import qs from 'qs';
import { currentCompany } from './utils';

/**
 * Extract param from ?param when param is either infornt or behide #
 * @param param param name to extract
 * @returns param value as string
 */
export const getParam = (param: string) => {
  const queryParams = qs.parse(window.location.hash.split('?')[1], {
    ignoreQueryPrefix: true,
  });
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get(param);

  let storedAccessToken = localStorage.getItem(param);
  console.log('storedAccessToken', storedAccessToken);
  if (typeof token === 'string') {
    return token;
  } else if (typeof queryParams?.[param] === 'string') {
    return queryParams[param] as string;
  } else {
    return false;
  }
};

/**
 * generate callback to program
 * @param path string path in url afer # ex. '/VisitNoPlane/detail'
 * @param queryString string query params after ? ex. 'param1=1&param2=2'
 * @returns
 */
export const generateCallBackURL = (path: string, queryString: string = '') => {
  // if (window.location.origin === 'file://') {
  let param = queryString === '' ? '?ShowPopUp=false' : '?ShowPopUp=false&' + queryString;
  return encodeURIComponent(window.location.origin + '/#' + path + param);
};
/**
 * generate callback to program
 * @param path string path in url afer # ex. '/VisitNoPlane/detail'
 * @param queryString string query params after ? ex. 'param1=1&param2=2'
 * @returns
 */
export const generateTechServCallBackURL = (path: string, queryString: string = '') => {
  // if (window.location.origin === 'file://') {
  if (window?.androidWebview) {
    //redirect for techserv intend
    let param = '&queryString=' + encodeURIComponent(queryString + '&timestamp=' + new Date().valueOf() + '&ShowPopUp=false');
    return encodeURIComponent(`techserv://subapp?appId=${process.env.REACT_APP_APP_ID}&subPath=${encodeURIComponent('#' + path)}` + param);
  } else {
    let param = queryString === '' ? '?ShowPopUp=false' : '?ShowPopUp=false&' + queryString;
    return encodeURIComponent(window.location.origin + '/#' + path + param);
  }
};
/**
 * Redirect to external "เบิกสินค้า"
 */
export const goToWithDrawApp = () => {
  const baseUrl = process.env.REACT_APP_WITHDRAW_URL;
  if (baseUrl == '') return false;
  const token = localStorage.getItem('accessToken');
  let param = `companyCode=${currentCompany().code}&callback=${generateTechServCallBackURL('')}&token=${token}`;
  const link = baseUrl + '?' + param;
  console.log('redirect to: ', link);
  window.open(link, '_self');
};

/**
 * Redirect to external "สินค้าบนรถ"
 */
export const goToVanApp = () => {
  const baseUrl = process.env.REACT_APP_VAN_URL;
  if (baseUrl == '') return false;
  const token = localStorage.getItem('accessToken');
  let param = `companyCode=${currentCompany().code}&callback=${generateTechServCallBackURL('')}&token=${token}`;
  const link = baseUrl + '?' + param;
  console.log('redirect to: ', link);
  window.open(link, '_self');
};

interface takeorder_param {
  type: 'cash' | 'credit' | 'future';
  customerId: string;
  callback: string;
}
export const takeorder = (data: takeorder_param) => {
  let baseUrl = '';
  const type = data.type;
  if (type == 'cash') {
    baseUrl = process.env.REACT_APP_TAKE_ORDER_CASH_URL!;
  } else if (type == 'credit') {
    baseUrl = '';
  }

  if (baseUrl == '') return false;

  const token = localStorage.getItem('accessToken');
  const param = `companyCode=${currentCompany().code}&customerId=${data.customerId}&paymentType=${type}&callback=${data.callback}&token=${token}`;

  const link = baseUrl + '?' + param;
  console.log('redirect to: ', link);
  window.open(link, '_self');
};
