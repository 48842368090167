import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  children?: ReactNode;
  reverse?: boolean;
}

const FooterModal: React.FC<Props> = ({ children, reverse = false }) => {
  return (
    <div className="pt-6">
      <div className={twMerge('footer border-t border-neutral-500 -mx-6 rounded-b-2xl flex xs:space-y-0 order-last', reverse ? '' : 'flex-row-reverse')} style={{ marginBottom: -24 }}>
        {children}
      </div>
    </div>
  );
};

export default FooterModal;
